.NotificationsButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .Button {
    padding: 0px;
    border-radius: 0px;
    box-shadow: none;
    width: 100%;
    height: 100%;

    &:active {
      box-shadow: none;
    }

    .NotificationsButton__semicircle {
      width: 9px;
      height: 5px;
      border-radius: 5px 5px 0 0;
      background-color: #46A1FC;
      transform: rotate(90deg);
      position: absolute;
      left: 16px;
      top: 28px;
    }
    
    .NotificationsButton__dot {
      width: 9px;
      height: 9px;
      border-radius: 5px;
      position: absolute;
      right: 2px;
      top: 26px;

      &.blue {
        background-color: #46A1FC;
      }
      &.red {
        background-color: #EA0909;
      }
    }
  }

  .Flyout__popper {
    max-width: 300px;
    margin-top: 8px;
    border-radius: 10px;
    width: 300px;
    height: 350px;
    background: #555;
  }
}