.ApprovalItem {
  padding: 8px 16px 8px 24px;

  .ApprovalItem__link {
    flex: 1;

    .Button {
      display: block;

      &:hover {
        color: white;
        text-decoration: none;
      }
  
      .Text {
        color: #FFF;
      }

      .link__info {
        .info__item {
          width: 100%;
    
          .item__date {
            white-space: nowrap;
          }
        }
      }
    }
  }

  &:hover {
    background: #777;
  }

  .ApprovalItem__actionButtons {
    padding: 0px;

    svg {
      cursor: pointer;
    }
  }
}