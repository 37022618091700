@import "../../../../constants";

.NavigationMenu {
  display: none;

  svg {
    margin: auto;
    cursor: pointer;

    path {
      fill: var(--white);
    }
  }

  @media (max-width: $screenMedium) {
    display: flex;
    width: 72px;
    height: 100%;
    padding: var(--space-xs);
    background: var(--steel-700);
    flex-shrink: 0;
  }
}