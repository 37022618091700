.MentionFlyout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 140px;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  .MentionFlyout__userPhoto {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 2px;
  }
}