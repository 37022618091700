.RouteBreadcrumbs {
  padding: var(--space-s) var(--space-l);
  width: 100%;

  span {
    &:hover {
      color: var(--prime-red-500);
    }
  }
}
