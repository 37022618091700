.Username {
  cursor: default;
  gap: 8px;
  display: flex;
  align-items: center;
  
  .Username__name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .Username__photo {
    position: relative;
    display: flex;
    align-items: center;

    .photo__tooltip {
      height: 24px;
    }

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    .Username__initials {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background-color: var(--prime-red-500);
      border-radius: 50%;

      .Text {
        color: white;
        font-size: 12px;
      }
    }
  }
}

.ant-avatar .Username {
  $avatarDiameter: 30px;
  padding: 0px;
  width: $avatarDiameter;
  height: $avatarDiameter;

  .Username__photo {
    height: inherit;
    width: inherit;
  }

  .photo__tooltip {
    height: $avatarDiameter;
    width: unset;

    .Username__initials {
      width: $avatarDiameter;
      height: $avatarDiameter;
    }

    img {
      width: inherit;
      height: inherit;
      border-radius: 0px;
    }
  }
}