.HomeButton {
  .HomeButton__CampaignInfo {
    cursor: pointer;
    
    .CampaignInfo__Container {
      .Container__Logo {
        flex: 1;
        min-width: 32px;
        width: 32px;
        
        path {
          width: 32px;
        }
      }
  
      .Text.H6 {
        color: var(--white);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }

  }
}