.NavigationItem {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0px;
      cursor: pointer;

      .ant-collapse-expand-icon {
        margin: auto;
      }

      &:hover {
        background: var(--steel-700);
      }
    }

    .ant-collapse-content .ant-collapse-content-box {
      padding: 0;
    }

    &:last-child .ant-collapse-header {
      border-radius: 4px;
    }
  }

  .NavigationItem__Link {
    display: flex;
    align-items: flex-start;
    padding: var(--space-s);

    .Text.H6 {
      color: var(--steel-400);
    }

    .Link__Icon {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .Link__Chevron {
      cursor: pointer;
    }
  
    &.active {
      .Text.H6, .Link__Chevron path {
        color: var(--white);
        fill: var(--white);
      }
    }
  
    &:hover {
      .Text.H6, .Link__Chevron path {
        color: var(--white);
        fill: var(--white);
      }
    }
  }

  .NavigationItem__Children {
    .NavigationItem__Link {
      padding: var(--space-xs) var(--space-m) var(--space-xs) 80px;
    }
  }
}