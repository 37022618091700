@import "../../../../constants";

.LeadStats {
  .LeadStats__DropdownTrigger {
    padding: 8px;

    &:hover {
      background-color: var(--grey-100);
      cursor: pointer;
      border-radius: 4px;
      padding: 8px;
    }
  }

  @media (max-width: $screenSmall) {
    .LeadStats__DropdownTrigger {
      display: none;
    }
  }
}
