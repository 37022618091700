@import "../../../../constants";

.NavigationPin {
  position: absolute;
  right: -7px;
  top: 122px;
  cursor: pointer;

  @media (max-width: $screenMedium) {
    display: none;
  }
}