@import "../../../../constants";

.LeadStatsOption {
  gap: var(--space-s);

  .LeadStatsOption__Divider {
    width: 1px;
    height: inherit;
    background: var(--grey-400);
  }

  .LeadStatsOption__Total {
    display: none;
    color: var(--grey-500);
  }

  @media (max-width: $screenSmall) {
    gap: var(--space-xs);

    .LeadStatsOption__Total {
      display: block;
    }

    .LeadStatsOption__Divider,
    .LeadStatsOption__Count,
    .LeadStatsOption__Value {
      display: none;
    }
  }
}