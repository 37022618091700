.Mention {
  display: inline-block;

  .Mention__text {
    margin: 0px 2px;
    cursor: default;

    &.contact {
      a[href^="mailto:"] {
        color: #084DFF;
      }
    }

    &.user {
      color: #FE3E59;
    }
  }
}