@import "../../../constants";

.TopNavigation {
  height: $topNavigationHeight;
  min-height: $topNavigationHeight;
  padding: 0px var(--space-s);
  background: var(--white);
  z-index: 2;
  flex: 1;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);

  .TopNavigation__LeftSection {
    display: flex;
    align-self: stretch;

    .LeftSection__CampaignName {
      display: none;
    }
  }

  .TopNavigation__Separator {
    display: none;
  }

  .TopNavigation__RightSection {
    align-self: stretch;

    .RightSection__actions {
      align-self: stretch;
    }

    .RightSection__Separator {
      width: 1px;
      height: 24px;
      background: var(--grey-400);
    }
  
    .RightSection__UserSection {
      height: 100%;
      padding: var(--space-s);
      border-radius: 0px;
    }
  }

  @media (max-width: $screenSmall) {
    .TopNavigation__LeftSection {
      .LeftSection__CampaignName {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }

    .TopNavigation__Separator {
      display: block;
      width: 1px;
      height: 32px;
      background: var(--grey-400);
      flex-shrink: 0;
    }

    .TopNavigation__RightSection .RightSection__Separator {
      height: 32px;
    }
  }

  @media (max-width: $screenMedium) {
    .TopNavigation__RightSection {
      .RightSection__UserSection {
        padding: 0px;

        .UserSection__Info {
          display: none;
        }
      }
    }

    .ContractSelector {
      display: none;
    }
  }
}

.TopNavigation__UserDropdown {
  border-radius: 0px;

  .ant-dropdown-menu {
    padding: var(--space-xs);

    .ant-dropdown-menu-item {
      padding: var(--space-xs) var(--space-s);
      border-radius: 0px;
    }
  }
}