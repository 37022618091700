$gap: 16px;

.dashboard-info {
  gap: $gap;
  flex-wrap: wrap;

  svg {
    path {
      fill: var(--black);
    }
  }

  .DashboardInfo__Section {
    gap: 5px;

    .DashboardInfo__leadReport {
      display: flex;
      align-items: center;
      gap: 4px;

      .Map__excludedCount {
        margin-top: 2px;
      }

      svg {
        path {
          fill: #FE3E59;
        }
      }
    }

    .ExportButton.Button {
      .Text.H6 {
        font-weight: 500;
      }
    }

    .Text {
      text-align: right;

      span {
        font-weight: 700;
      }
    }

    .Button {
      padding: 0px;
    }
  }

  .DashboardInfo__Upload {
    height: 100%;
    border-left: 0.5px solid #4D4747;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 12px;
  }

  a {
    display: flex;
  }
}