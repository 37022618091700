.CurrentScoreCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  .CurrentScoreCard__scoreCard {
    position: absolute;
    top: 65px;
    right: -180px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 15px;
    width: 500px;
    height: 380px;
    cursor: default;

    .scoreCard__chartContainer {
      width: 100%;
      height: 100%;
      overflow-x: auto;
    }
  }

  .CurrentScoreCard__topHeader {
    display: flex;
    padding-left: 15px;

    .dateRange {
      text-align: right;
      flex: 1;
    }
  }
  
  svg {
    width: 24px;
    height: 24px;
  }
}