@import "../../../constants";

.SideNavigationContainer {
  position: relative;
  width: $sideNavigationWidth;
  min-width: fit-content;
  flex-shrink: 0;
  z-index: 3;

  @media (max-width: $screenMedium) {
    display: none;
  }
}

.SideNavigationDrawer .ant-drawer-content {
  background: transparent;
}

.SideNavigation {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: $sideNavigationWidth;
  height: 100vh;
  background: var(--steel-900);
  transition: width 300ms ease-out;

  @keyframes navLabelFadeInAnimation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.closed {
    .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
      display: none;
    }
  }

  &.expand {
    width: $sideNavigationExpandedWidth;

    .NavigationItem, .NavItems__Support {
      .Text.H6 {
        animation-name: navLabelFadeInAnimation;
        animation-duration: 300ms;
        animation-timing-function: ease-out;
        white-space: nowrap;
      }
    }
  }

  &.open {
    position: static;
    width: $sideNavigationExpandedWidth;
  }

  .SideNavigation__HomeSection {
    height: $topNavigationHeight;
    background: var(--steel-700);
    padding: var(--space-xs) var(--space-s);

    .HomeSection__NavigationMenu {
      display: none;
    }
  }

  .SideNavigation__NavItems {
    padding: var(--space-xs);
    overflow-y: auto;

    .NavItems__Container {
      padding-right: var(--space-xs);
    }

    .NavItems__Support {
      padding: var(--space-s);

      .Text.H6 {
        color: var(--white);
      }
    }

    .ContractSelector {
      display: none;
    }
  }

  @media (max-width: $screenMedium) {
    &.expand {
      width: 80vw;
    }

    .SideNavigation__NavItems .ContractSelector {
      display: block;

      .ant-dropdown-trigger {
        border: 1px solid var(--steel-600);
        background: var(--steel-800);
        height: 48px;

        input {
          color: var(--white);

          &::placeholder {
            color: var(--white);
          }
        }
      }
    }

    .SideNavigation__HomeSection {
      .HomeSection__NavigationMenu {
        display: block;
        flex-shrink: 0;
        cursor: pointer;

        path {
          fill: var(--white);
        }
      }

      .HomeButton {
        padding: 12px;
      }
    }
  }
}