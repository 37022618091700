.AddressField {
  flex: 1;

  .SearchOption {
    .Address {
      width: -webkit-fill-available;
      .Text {
        padding: 0px;
      }
    }
  }
}