@import "constants";

:root {
  // Spacing
  --space-xxs: 4px;
  --space-xs: 8px;
  --space-s: 16px;
  --space-m: 24px;
  --space-l: 32px;
  --space-xl: 48px;
  --space-xxl: 64px;
  --space-xxxl: 96px;

  // Theme colors

  /* Surface */
  --background-surface-primary: var(--white);
  --background-surface-secondary: var(--grey-200);
  --background-surface-tertiary: var(--grey-400);

  /* Banners */
  --background-banner-primary: var(--peach-500);
  --background-banner-secondary: var(--abyss-800);

  /* Bubbles */
  --background-bubble-primary: var(--orange-100);
  --background-bubble-secondary: var(--orange-200);

  /* Buttons */
  --background-button-primary: var(--sunrise-500);
  --background-button-secondary: var(--abyss-800);
  --background-button-disabled: var(--grey-500);

  /* Input */
  --background-input: var(--white);

  /* Text */
  --text-primary: var(--grey-800);
  --text-secondary: var(--white);
  --text-subtitle: var(--grey-600);
  --text-placeholder: var(--grey-500);
  --text-accent: var(--prime-blue-600);

  /* Border */
  --border-primary: var(--grey-400);
  --border-secondary: var(--grey-300);
  --border-primary-hover: var(--prime-blue-300);
  --border-primary-error: var(--tomato-600);
  --border-primary-pressed: var(--prime-blue-500);

  /* Icons */
  --icon-primary: var(--grey-500);
  --icon-secondary: var(--white);
  --icon-accent: var(--prime-blue-600);
  --icon-gradient: var(--azure-500);
  --icon-navigation-active: var(--sunrise-500);
  --icon-success: var(--lime-600);

  /* Shadows */
  --shadow-dark: var(--black);

  // Primary colors
  --prime-red-100: #FFDFE3;
  --prime-red-200: #FFBFC8;
  --prime-red-300: #FF9FAC;
  --prime-red-400: #FE7E90;
  --prime-red-500: #FE3E59;
  --prime-red-600: #E32641;
  --prime-red-700: #CE122C;
  --prime-red-800: #A31A2D;
  --prime-red-900: #330C12;

  --prime-blue-100: #E9EFFF;
  --prime-blue-200: #D6E1FF;
  --prime-blue-300: #84A6FF;
  --prime-blue-400: #5A88FF;
  --prime-blue-500: #3E74FE;
  --prime-blue-600: #245AE3;
  --prime-blue-700: #1247CE;
  --prime-blue-800: #1A3BA6;
  --prime-blue-900: #031D37;

  // Neutral colors
  --grey-100: #F5F5F5;
  --grey-200: #F0F0F0;
  --grey-300: #DDDDDD;
  --grey-400: #BBBBBB;
  --grey-500: #888888;
  --grey-600: #666666;
  --grey-700: #444444;
  --grey-800: #2C2C2C;
  --grey-900: #141414;

  --steel-100: #E6EBF3;
  --steel-200: #CAD3E1;
  --steel-300: #A8B4C2;
  --steel-400: #8795A3;
  --steel-500: #657684;
  --steel-600: #435765;
  --steel-700: #223846;
  --steel-800: #102937;
  --steel-900: #001927;

  --white: #FFFFFF;
  --black: #000000;

  // Secondary colors
  --lime-100: #F0FCDE;
  --lime-200: #DEFABE;
  --lime-300: #C3F199;
  --lime-400: #A6E37C;
  --lime-500: #7FD153;
  --lime-600: #5EB33C;
  --lime-700: #429629;
  --lime-800: #29791A;
  --lime-900: #18640F;

  --tomato-100: #FDE9DD;
  --tomato-200: #FCCFBC;
  --tomato-300: #F8AC99;
  --tomato-400: #F18C7E;
  --tomato-500: #E85A55;
  --tomato-600: #C73E45;
  --tomato-700: #A72A3B;
  --tomato-800: #861B32;
  --tomato-900: #6F102D;

  --orange-100: #FFECDD;
  --orange-200: #FFD9BA;
  --orange-300: #FFC698;
  --orange-400: #FFA052;
  --orange-500: #FF8D30;
  --orange-600: #E77F2A;
  --orange-700: #C4671C;
  --orange-800: #93511A;
  --orange-900: #6C370D;

  --sky-100: #DAF3FE;
  --sky-200: #B5E4FE;
  --sky-300: #8FD2FE;
  --sky-400: #74BFFD;
  --sky-500: #46A1FC;
  --sky-600: #337DD8;
  --sky-700: #235DB5;
  --sky-800: #164192;
  --sky-900: #0D2D78;

  --cyan-100: #CCFFFC;
  --cyan-200: #99FCFF;
  --cyan-300: #66F0FF;
  --cyan-400: #3FDEFF;
  --cyan-500: #00C2FF;
  --cyan-600: #0097DB;
  --cyan-700: #0071B7;
  --cyan-800: #005093;
  --cyan-900: #003A7A;

  --yellow-100: #FFF9CC;
  --yellow-200: #FFF199;
  --yellow-300: #FFE866;
  --yellow-400: #FFDE3F;
  --yellow-500: #FFCF00;
  --yellow-600: #DBAD00;
  --yellow-700: #B78D00;
  --yellow-800: #936F00;
  --yellow-900: #7A5900;

  --pink-100: #FFE9F3;
  --pink-200: #FFBCDA;
  --pink-300: #FF90C1;
  --pink-400: #FF64A8;
  --pink-500: #FF2182;
  --pink-600: #E82C7E;
  --pink-700: #C41C66;
  --pink-800: #931A4F;
  --pink-900: #6D0D37;

  --violet-100: #F6E3FF;
  --violet-200: #E9BAFF;
  --violet-300: #DB8EFF;
  --violet-400: #CD66FF;
  --violet-500: #C03DFF;
  --violet-600: #AA2CE8;
  --violet-700: #8B1CC2;
  --violet-800: #6C1A93;
  --violet-900: #4F0D6D;

  --slate-100: #E6ECEF;
  --slate-200: #CDD9DE;
  --slate-300: #B4C6CE;
  --slate-400: #9BB3BD;
  --slate-500: #6A8D9C;
  --slate-600: #517A8C;
  --slate-700: #38677B;
  --slate-800: #1F546B;
  --slate-900: #06415A;

  // Gradient colors
  --sunrise-100: linear-gradient(281deg, var(--pink-200), var(--orange-100));
  --sunrise-200: linear-gradient(281deg, var(--pink-300), var(--orange-200));
  --sunrise-400: linear-gradient(281deg, var(--pink-400), var(--orange-300));
  --sunrise-500: linear-gradient(281deg, var(--pink-500), var(--orange-400));
  --sunrise-600: linear-gradient(281deg, var(--pink-600), var(--orange-500));
  --sunrise-800: linear-gradient(281deg, var(--pink-800), var(--orange-700));

  --azure-100: linear-gradient(103deg, var(--cyan-100), var(--prime-blue-200));
  --azure-200: linear-gradient(103deg, var(--cyan-200), var(--prime-blue-300));
  --azure-400: linear-gradient(103deg, var(--cyan-400), var(--prime-blue-500));
  --azure-500: linear-gradient(103deg, var(--cyan-500), var(--prime-blue-600));
  --azure-600: linear-gradient(103deg, var(--cyan-600), var(--prime-blue-700));
  --azure-800: linear-gradient(103deg, var(--cyan-800), var(--prime-blue-900));

  --peach-100: linear-gradient(282deg, var(--orange-100), var(--prime-red-100));
  --peach-200: linear-gradient(282deg, var(--orange-100), var(--prime-red-200));
  --peach-400: linear-gradient(282deg, var(--orange-200), var(--prime-red-300));
  --peach-500: linear-gradient(282deg, var(--orange-300), var(--prime-red-400));
  --peach-600: linear-gradient(282deg, var(--orange-400), var(--prime-red-500));
  --peach-800: linear-gradient(282deg, var(--orange-600), var(--prime-red-700));

  --blueberry-100: linear-gradient(92deg, var(--prime-blue-100), var(--violet-200));
  --blueberry-200: linear-gradient(92deg, var(--prime-blue-200), var(--violet-300));
  --blueberry-400: linear-gradient(92deg, var(--prime-blue-300), var(--violet-400));
  --blueberry-500: linear-gradient(92deg, var(--prime-blue-400), var(--violet-500));
  --blueberry-600: linear-gradient(92deg, var(--prime-blue-500), var(--violet-600));
  --blueberry-800: linear-gradient(92deg, var(--prime-blue-500), var(--violet-800));

  --grass-100: linear-gradient(95deg, var(--green-100), var(--green-300));
  --grass-200: linear-gradient(95deg, var(--green-200), var(--green-400));
  --grass-400: linear-gradient(95deg, var(--green-300), var(--green-500));
  --grass-500: linear-gradient(95deg, var(--green-400), var(--green-600));
  --grass-600: linear-gradient(95deg, var(--green-500), var(--green-700));
  --grass-800: linear-gradient(95deg, var(--green-700), var(--green-900));

  --abyss-100: linear-gradient(95deg, var(--slate-200), var(--steel-100));
  --abyss-200: linear-gradient(95deg, var(--slate-300), var(--steel-200));
  --abyss-400: linear-gradient(95deg, var(--slate-500), var(--steel-400));
  --abyss-500: linear-gradient(95deg, var(--slate-600), var(--steel-500));
  --abyss-600: linear-gradient(95deg, var(--slate-700), var(--steel-600));
  --abyss-800: linear-gradient(95deg, var(--slate-900), var(--steel-900));
}

#appLayer {
  #mainLayer {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    #pageLayer {
      display: flex;
      flex-direction: column;
      position: relative;
      height: calc(100% - $topNavigationHeight);
      z-index: 1;

      .AdminRoute, .BaseRoute {
        flex: 1;
        overflow: auto;
      }
    }
  }

  #publicLayer {
    height: 100vh;
    overflow: auto;
  }
}

.AdminTitle {
  color: #435765;
  margin-bottom: 24px;
}

.AdminSubtitle {
  margin-bottom: 16px;
}

a, .Link {
  color: var(--prime-red-500);
  text-decoration: none;
}

.flex {
  display: flex;
}

.flex-align {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: baseline;
  width: 100%;
}

.flex-center-space-between {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

label {
  font-family: Roboto, Helvetica, Arial;
  font-style: normal;
  color: #2C2C2C;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  display: block;
}

.Text {
  &.PageTitle {
    color: #435765;
    &.H2 {
      margin-top: 40px;
      margin-bottom: 24px;
    }
    &.H3 {
      padding-left: 16px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}

h2, h4, p {
  margin-top: 0px;
  margin-bottom: 0px;
}

h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: normal;
}

*, *::before, *::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Roboto, Helvetica, Arial;
}

.wingmate-gradient {
  background: rgba($gradientStart, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba($gradientStart, 1) 0%,
    rgba($gradientEnd, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, rgba($gradientStart, 1)),
    color-stop(100%, rgba($gradientEnd, 1))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba($gradientStart, 1) 0%,
    rgba($gradientEnd, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba($gradientStart, 1) 0%,
    rgba($gradientEnd, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba($gradientStart, 1) 0%,
    rgba($gradientEnd, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba($gradientStart, 1) 0%,
    rgba($gradientEnd, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradientStart', endColorstr='$gradientEnd', GradientType=1 );
}

.MainColumn {
  max-width: 850px;
  gap: 24px;
}

.SideColumn {
  max-width: 410px;
}

.MergeModalPage {
  height: 580px;
}

.ReminderFormModal {
  max-width: 800px;

  &.modal__content {
    top: 30px;
    padding: 5px;
    @media (max-width: 800px) {
      width: 98%;
    }

    @media (max-height: 768px) {
      overflow-y: auto;
      max-height: 90%;
      display: block;
    }
  }
}

.react-toggle {
  &.FilterToggle {
    border: none;
    border-radius: 0px;
    display: flex;
    align-items: center;
    background-color: transparent;
    box-shadow: none;
    padding: 4px 8px;
    height: 40px;
  
    &.on {
      cursor: default;
      background: #FFE1E1;
      .Text {
        color: var(--prime-red-500);
        font-weight: 600;
      }
    }
  }
}
