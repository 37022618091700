.FieldLabel {
  width: fit-content;
  
  sup {
    padding: 3px;
  }
  
  .FieldLabel__asterisk {
    fill: #FC0101;
  }
}